html {
  scroll-behavior: smooth;
}

body{
  margin: 20px;
  margin-bottom: 100px;
  text-align: center;
  background: lightblue;
  color: blue;
  font-family: 'Permanent Marker', cursive;
  font-size: 20px;
}

button {
  cursor: pointer;
}

a {
  text-decoration: none;
}

.error{
  font-family: 'Roboto',sans-serif;
}

.pickFileButton {
  font-family: 'Helvetica',sans-serif;
  font-size: 36px;
  color: white;
  background: blue;
  border: 0px;
  padding: 7px;
  border-radius: 7px;
  margin: 5px;
}

.uploadButton {
  font-family: 'Helvetica',sans-serif;
  font-size: 36px;
  color: white;
  background: green;
  border: 0px;
  padding: 7px;
  border-radius: 7px;
  margin: 5px;
}

.portraitButton {
  font-family: 'Helvetica',sans-serif;
  font-size: 36px;
  color: white;
  background: green;
  border: 0px;
  padding: 7px;
  border-radius: 7px;
  margin: 5px;
}

.downloadButton {
  font-family: 'Helvetica',sans-serif;
  font-size: 50px;
  color: white;
  background: green;
  border: 0px;
  padding: 7px;
  border-radius: 7px;
  margin: 5px;
}

.resetButton {
  font-family: 'Helvetica',sans-serif;
  font-size: 30px;
  color: black;
  background: white;
  border: 0px;
  padding: 7px;
  border-radius: 7px;
  margin: 5px;
}

.choose1, .choose2, .choose3 {
  cursor: pointer;
  color: blue;
}

.choose1 img, .choose2 img, .choose3 img {
  height: 300px;
}

.footer{
  font-family: 'Roboto',sans-serif;
  font-size: .75em;
  list-style: none;
  margin-top: 800px;
  margin-bottom: -80px;
}

.footer img {
  height: 75px;
}

#copy{

}

@media only screen and (max-width: 767px) {

  .textHolder{
    background: blue;
    width: 70%;
    margin: auto;
  }

  .text{
    font-family: 'Roboto',sans-serif;
    font-size: 2em;
    color: white;
    padding: 15px 5px 15px 5px;
  }

  .headerWrapper{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    gap: 10px;
    grid-auto-rows: minmax(100px, auto);
  }

  .headerBanner{
    grid-column: 1/1;
    grid-row: 1;
  }

  .headerLogo{
    grid-column: 1 / 1;
    grid-row: 2;
    cursor: pointer;
  }

  .headerPic{
    grid-column: 1/1;
    grid-row: 3;
  }

  .headerPic img{
    height: 250px;
  }

  .headerLogo img{
    height: 200px;
  }

  .chooseWrapper{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    gap: 10px;
    grid-auto-rows: minmax(100px, auto);
  }

  .choose1 {
    grid-column: 1 / 1;
    grid-row: 1;
  }

  .choose2 {
    grid-column: 1 / 1;
    grid-row: 2;
  }

  .choose3 {
    grid-column: 1 / 1;
    grid-row: 3;
  }

}

@media only screen and (min-width: 768px) {

  .textHolder{
    background: blue;
    width: 35%;
    margin: auto;
  }

  .text{
    font-family: 'Roboto',sans-serif;
    font-size: 2em;
    color: white;
    padding: 15px 5px 15px 5px;
  }

  .headerWrapper{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    gap: 10px;
    grid-auto-rows: minmax(100px, auto);
  }

  .headerBanner{
    grid-column: 3 / 4;
    grid-row: 1;
  }

  .headerLogo{
    grid-column: 1 / 2;
    grid-row: 1;
    cursor: pointer;
  }

  .headerPic{
    grid-column: 2 / 3;
    grid-row: 1;
  }

  .headerPic img{
    height: 275px;
  }

  .headerLogo img{
    height: 250px;
  }

  .chooseWrapper{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    gap: 10px;
    grid-auto-rows: minmax(100px, auto);
  }

}

